<template>
    <div>
        <router-view v-if="$route.path.includes('vision') || $route.path.includes('answer') || $route.path.includes('copyright') || $route.path.includes('privacy-policy') || $route.path.includes('user-agreement')" ></router-view>
        <div class="about-box" v-else>
            <title-nav :greyBG="true"/>
            <div class="content">
                <div class="logo">
                    <img :src="logo" alt="" width="60" height="60">
                    <div class="version">Version 1.0.0</div>
                </div>
                <div class="panel">
                    <div class="row" @click="answer">
                        <span>常见问题</span>
                        <img src="../../assets/images/enter.png" alt="" width="20" height="20">
                    </div>
                    <div class="row" @click="privacyPolicy">
                        <span>隐私政策</span>
                        <img src="../../assets/images/enter.png" alt="" width="20" height="20">
                    </div>
                    <div class="row" @click="userAgreement">
                        <span>用户协议</span>
                        <img src="../../assets/images/enter.png" alt="" width="20" height="20">
                    </div>
                    <div class="row" @click="copyright">
                        <span>版权证书</span>
                        <img src="../../assets/images/enter.png" alt="" width="20" height="20">
                    </div>
                    <div class="row" @click="aboutUs">
                        <span>关于</span>
                        <img src="../../assets/images/enter.png" alt="" width="20" height="20">
                    </div>
                </div>
            </div>
            <div class="copyright">
                <div class="logo">Copyright © 2023</div>
                <div class="onwer">小天逻辑 版权所有</div>
            </div>
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
const seo = require('../../../config/index')
export default {
  name: 'About',
  components: {
    TitleNav
  },
  data(){
      return {
        logo: seo.config.logo
      }
  },
  methods:{
    aboutUs(){
        this.$router.push('/about/vision')
    },
    answer(){
        this.$router.push('/about/answer')
    },
    copyright(){
        this.$router.push('/about/copyright')
    },
    privacyPolicy(){
        this.$router.push('/about/privacy-policy')
    },
    userAgreement(){
        this.$router.push('/about/user-agreement')
    }
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.about-box {
    height: 100vh;
    background: #f3f3f3;
    .content {
        margin: 90px 0 100px;
        .logo {
            img {
                border-radius: 10px;
                width: 60px;
                height: 60px;
                background: #ededed;
            }
            .version {
                margin: 10px 0;
            }
        }
        .panel {
            margin: 40px 0;
            .row {
                display: flex;
                background: #fff;
                height: 25px;
                padding: 10px 10px;
                border: 1px solid #f3f3f3;
                justify-content: space-between;
                align-items: center;
                span {
                    padding: 0 10px;
                }
            }
        }
    }
    .copyright {
        color: #969699;
        position: fixed;
        bottom: 20px;
        right: 0;
        left: 0;
        .logo {
            font-size: 14px;
            height: 20px;
        }
        .onwer {
            font-size: 14px;
            height: 20px;
        }
    }
}
</style>
